import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import "./layout.css"

const Layout = ({ children }) => {
  return (
    <LayoutWrapper>
      <main>{children}</main>
    </LayoutWrapper>
  )
}

const LayoutWrapper = styled.div`
  margin: 0 auto;
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
